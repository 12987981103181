import { Link } from 'gatsby';
import React from 'react';
import { string } from 'prop-types';

const determineActiveClass = (route, pageRenderPath) => {
  const routeFirstPath = route.split('/').filter((path) => path)[0];
  const pageRenderPathFirstPath = decodeURI(pageRenderPath)
    .split('/')
    .filter((path) => path)[0];

  return routeFirstPath === pageRenderPathFirstPath ? 'active' : '';
};

/**
 * Generate a tabnavigation link
 * @param {object} props
 * @param {string} props.url
 * @param {string} props.displayName
 *
 * @returns {JSX.Element}
 */
const MakeTabnavigationLink = ({ url, displayName, pageRenderPath }) => {
  const route = decodeURI(url);
  const activeClassName = determineActiveClass(route, pageRenderPath);

  return (
    <span className="ea-tabnavigation__li-wrapper">
      <li className="ea-tabnavigation__li">
        <Link
          to={route}
          className={`ea-tabnavigation__link ${activeClassName}`}
        >
          {displayName}
        </Link>
      </li>
    </span>
  );
};

MakeTabnavigationLink.propTypes = {
  url: string.isRequired,
  displayName: string.isRequired,
  pageRenderPath: string.isRequired
};

export default MakeTabnavigationLink;
