import { Slider } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const EonSlider = withStyles(Slider, () => ({
  root: {
    color: '#bfbfbf',
    padding: '15px 0',
    margin: 0
  },
  thumb: {
    height: 18,
    width: 18,
    borderRadius: 3,
    backgroundColor: '#ea1c0a',
    // marginTop: -9,
    // marginLeft: -9,
    transition: 'none',
    '&:focus, &:hover, &$active': { boxShadow: 'inherit' }
  },
  track: {
    height: 2
  },
  rail: {
    height: 2,
    borderRadius: 3,
    opacity: 0.5,
    backgroundColor: '#bfbfbf'
  },
  active: {},
  mark: {
    opacity: 1,
    backgroundColor: '#bfbfbf',
    height: 6,
    width: 6
    // marginTop: -2
  },
  markLabel: {
    fontFamily:
      'EON_BrixSansWeb, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue',
    fontSize: 10,
    fontWeight: 'normal',
    top: 0,
    marginTop: 30,
    opacity: 1,
    color: '#000'
  },
  markLabelActive: {
    color: '#ea1c0a'
  },
  valueLabel: {
    backgroundColor: '#ea1c0a',
    // color: '#ea1c0a',
    fontSize: 8,
    textAlign: 'center'
    // marginLeft: '5px'
  }
}));

export default EonSlider;
