import React from 'react';
import TabnavigationLink from '../TabnavigationLink';

/**
 * Generate tabnavigation links
 * @param {object} routes
 * @returns {ReactNode[]} - ReactNodes
 */
export default function makeTabnavigationLinks(pageRenderPath, routes) {
  return routes.map((route) => (
    <TabnavigationLink
      url={route.url}
      key={route.url}
      displayName={route.displayName}
      pageRenderPath={pageRenderPath}
    />
  ));
}
