import React from 'react';
import queryString from 'query-string';
import { Location } from '@gatsbyjs/reach-router';

/* eslint-disable react/jsx-props-no-spreading */
const withLocation = (ComponentToWrap) =>
  // eslint-disable-next-line func-names
  function (props) {
    return (
      <Location>
        {({ location, navigate }) => (
          <ComponentToWrap
            {...props}
            location={location}
            navigate={navigate}
            rawSearch={location.search}
            search={location.search ? queryString.parse(location.search) : {}}
          />
        )}
      </Location>
    );
  };
/* eslint-enable react/jsx-props-no-spreading */

export default withLocation;
