import axios from 'axios';
import LocalStorage from './Store';

const defaultOptions = {
  disableStorage: false
};

class DataService {
  constructor(options = defaultOptions) {
    this.options = options;
    this.parser = options.parser;
    this.store = options.store || new LocalStorage();
    this.fetchOptions = {};
  }

  /**
   * @param {object} data
   * @param {number} delay
   * @returns {Promise<unknown>}
   *
   * @private
   */
  static delayResponse(data, delay = 0) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(data);
      }, delay);
    });
  }

  /**
   * @param data
   * @returns {*}
   *
   * @private
   */
  parsedData(data) {
    if (!this.parser) return data;

    return this.parser.generate(data);
  }

  /**
   * @param url
   * @returns {Promise<unknown>}
   *
   * @private
   */
  fetchAsync(url) {
    const { key, disableStorage } = this.fetchOptions;

    return axios.get(url).then((value) => {
      if (!disableStorage) {
        this.store.set(key, value.data);
      }

      const data = this.parsedData(value.data);

      return DataService.delayResponse(data, this.fetchOptions.delay);
    });
  }

  /* eslint-disable class-methods-use-this */
  generateFetchOptions(url, options) {
    return {
      ...options,
      key: options.key ? options.key : url
    };
  }
  /* eslint-enable class-methods-use-this */

  /**
   * Get data from localStorage or fetch data async
   *
   * @param {string} url
   * @param {object} options
   * @param {number} [options.delay]
   * @param {string} [options.key]
   * @returns {Promise<AxiosResponse<any>>}
   */
  getData(url, options = {}) {
    this.fetchOptions = this.generateFetchOptions(url, options);

    const storeData = this.store.get(this.fetchOptions.key);

    if (storeData) return this.parsedData(storeData);

    return this.fetchAsync(url);
  }
}

export default DataService;
