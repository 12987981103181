import PropTypes from 'prop-types';
import * as React from 'react';

export const FeatureFlagsContext = React.createContext();

const FeatureFlagsProvider = ({ children, flags }) => {
  const normalizedFlags = flags.reduce((acc, value) => {
    acc[value.name] = value;
    return acc;
  }, {});

  return (
    <FeatureFlagsContext.Provider value={normalizedFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

FeatureFlagsProvider.defaultProps = {
  flags: []
};

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  flags: PropTypes.array
};

// eslint-disable-next-line import/prefer-default-export
export { FeatureFlagsProvider };
