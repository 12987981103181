const GRUENE_ENERGIEMEISTER_PART = '/gruene-energiemeister';
const E_MOBILITY_PART = '/e-mobility';
const HAUSHALT_PART = '/haushalte';

const ZUKUNFTSINDEX_PART = '/zukunftsindex';
const DURCH_ENERGIEWENDE_ZUHAUSE_PART = `${ZUKUNFTSINDEX_PART}/durch-energiewende-zuhause`;
const DURCH_VERKEHRSWENDE_PART = `${ZUKUNFTSINDEX_PART}/durch-verkehrswende`;
const DURCH_VERBRAUCHSWENDE_PART = `${ZUKUNFTSINDEX_PART}/durch-verbrauchswende`;

module.exports = {
  ZUKUNFTSINDEX_PART,
  ZUKUNFTSINDEX: {
    CO2_EINSPARUNG_JAHR_GEPLANT: `${ZUKUNFTSINDEX_PART}/co2-einsparung-jahr`,
    DURCH_ENERGIEWENDE_ZUHAUSE_PART,
    DURCH_ENERGIEWENDE_ZUHAUSE: {
      GESAMT: `${DURCH_ENERGIEWENDE_ZUHAUSE_PART}/durch-energiewende-zuhause-gesamt`,
      INSTALLATION_DACH_SOLARANLAGEN: `${DURCH_ENERGIEWENDE_ZUHAUSE_PART}/durch-energiewende-zuhause-installation-dach-solaranlage`,
      INSTALLATION_BALKON_SOLARANLAGE: `${DURCH_ENERGIEWENDE_ZUHAUSE_PART}/durch-energiewende-zuhause-installation-balkon-solaranlage`,
      EFFIZIENTE_HEIZANALGE: `${DURCH_ENERGIEWENDE_ZUHAUSE_PART}/durch-energiewende-zuhause-effiziente-heizanlage`
    },
    DURCH_VERKEHRSWENDE_PART,
    DURCH_VERKEHRSWENDE: {
      GESAMT: `${DURCH_VERKEHRSWENDE_PART}/durch-verkehrswende-gesamt`,
      UMSTIEG_E_AUTO: `${DURCH_VERKEHRSWENDE_PART}/durch-verkehrswende-umstieg-e-auto`,
      UMSTIEG_OEPNV: `${DURCH_VERKEHRSWENDE_PART}/durch-verkehrswende-umstieg-oepnv`,
      BIDIREKTIONALES_LADEM: `${DURCH_VERKEHRSWENDE_PART}/durch-verkehrswende-bidirektionales-laden`
    },
    DURCH_VERBRAUCHSWENDE_PART,
    DURCH_VERBRAUCHSWENDE: {
      GESAMT: `${DURCH_VERBRAUCHSWENDE_PART}/durch-verbrauchswende-gesamt`,
      HEIZTEMERATUR_SENKEN: `${DURCH_VERBRAUCHSWENDE_PART}/durch-verbrauchswende-heiztemperatur-senken`,
      SMARTE_THERMOSTATE: `${DURCH_VERBRAUCHSWENDE_PART}/durch-verbrauchswende-smarte-thermostate`
    }
  },

  GRUENE_ENERGIEMEISTER_PART,
  GRUENE_ENERGIEMEISTER: {
    MEISTER_INDEX: `${GRUENE_ENERGIEMEISTER_PART}/meister-index`,
    EE_ANLAGEN_PART: `${GRUENE_ENERGIEMEISTER_PART}/ee-anlagen`,
    EE_ANLAGEN: {
      GESAMT: `${GRUENE_ENERGIEMEISTER_PART}/ee-anlagen/gesamt`,
      WIND: `${GRUENE_ENERGIEMEISTER_PART}/ee-anlagen/wind`,
      SOLAR: `${GRUENE_ENERGIEMEISTER_PART}/ee-anlagen/solar`,
      WASSERKRAFT: `${GRUENE_ENERGIEMEISTER_PART}/ee-anlagen/wasserkraft`,
      BIOMASSE: `${GRUENE_ENERGIEMEISTER_PART}/ee-anlagen/biomasse`
    },

    OEKOSTROMNUTZER: `${GRUENE_ENERGIEMEISTER_PART}/oekostromnutzung`,
    PATENTE: `${GRUENE_ENERGIEMEISTER_PART}/patente`,
    ENERGIEMEISTER_LADESTATIONEN: `${GRUENE_ENERGIEMEISTER_PART}/gruene-ladestationen`
  },

  E_MOBILITY_PART,
  E_MOBILITY: {
    LADESTATIONEN: `${E_MOBILITY_PART}/ladestationen`,
    E_AUTO_BESITZER: `${E_MOBILITY_PART}/e-auto-besitzer`,
    E_BIKES: `${E_MOBILITY_PART}/e-bikes`
  },

  HAUSHALT_PART,
  HAUSHALT: {
    VORSAETZE: `${HAUSHALT_PART}/vorsaetze`,
    STREAMING: `${HAUSHALT_PART}/streaming`
  }
};
