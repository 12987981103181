import React, { useContext, useEffect, useState, useRef } from 'react';
import { any, number } from 'prop-types';
import {
  GlobalDispatchContext,
  GlobalStateContext,
  SET_TIMELINE_FILTER
} from '../../../context/GlobalContextProvider';
import withLocation from '../../../HOCs/withLocation/withLocation';
import Box from '../../../shared-components/Box/Box';
import EonSlider from './Slider';
import './Timeline.scss';
import pathnameUtilities from '../../../services/PathnameUtilities';
import timelineHelper from '../TimelineHelper';
import TimelineApi from '../TimelineApi';

const Timeline = ({ location, initialYear }) => {
  const timelineApiRef = useRef(new TimelineApi(location.pathname));
  const [currentPathname, setCurrentPathname] = useState(null);
  const [sliderOptions, setSliderOptions] = useState({ marks: [] });
  const globalDispatchContext = useContext(GlobalDispatchContext);
  const globalStateContext = useContext(GlobalStateContext);
  const [sliderValue, setSliderValue] = useState(initialYear);

  useEffect(() => {
    setCurrentPathname(
      pathnameUtilities.createPathnameWithoutTrainlingSlash(location.pathname)
    );

    setSliderValue(initialYear);
  }, [location.pathname, initialYear]);

  useEffect(() => {
    const loadAdditionalData = async () => {
      timelineApiRef.current.setPathname(location.pathname);
    };

    loadAdditionalData();
  }, [globalStateContext.year, location.pathname]);

  useEffect(() => {
    setSliderOptions(
      timelineHelper.createSliderOptions(
        globalStateContext.year,
        globalStateContext.availableYears,
        currentPathname
      )
    );
  }, [
    globalStateContext.year,
    globalStateContext.availableYears,
    currentPathname
  ]);

  /**
   * Handle slider value change
   *
   * @param _
   * @param {number} selected
   */
  const onSliderChange = (_, selected) => {
    /* eslint-disable-next-line */
    globalDispatchContext({ type: SET_TIMELINE_FILTER, payload: selected });
  };

  const handleSliderChange = (_, value) => {
    setSliderValue(value);
  };

  const renderSliderContent = () => {
    return (
      <div className="ea-timeline">
        <Box className="ea-box--overflow-visible">
          {sliderOptions.marks.length > 0 && sliderOptions.defaultValue && (
            <EonSlider
              track={false}
              value={sliderValue}
              aria-labelledby="timeline"
              valueLabelFormat={(mark) =>
                timelineHelper.createSliderBubbleLabel(mark, currentPathname)
              }
              step={null}
              marks={sliderOptions.marks}
              min={sliderOptions.min}
              max={sliderOptions.max}
              onChangeCommitted={onSliderChange}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
            />
          )}
        </Box>
      </div>
    );
  };

  if (sliderOptions.marks.length) {
    return renderSliderContent();
  }

  return null;
};

Timeline.propTypes = {
  location: any.isRequired,
  initialYear: number.isRequired
};

export default withLocation(Timeline);
