import classnames from 'classnames';
import { bool } from 'prop-types';
import React, { useContext, useState } from 'react';
import { GlobalStateContext } from '../../../context/GlobalContextProvider';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
import './FullscreenLoader.scss';

const FullscreenLoader = ({ isSmall }) => {
  const globalState = useContext(GlobalStateContext);
  const hasPendingRequests = Boolean(globalState.pendingRequests);
  const [showLoader, setShowLoader] = useState(false);

  useDebouncedEffect(
    () => {
      setShowLoader(hasPendingRequests);
    },
    50,
    [hasPendingRequests]
  );

  const loaderClasses = classnames('ea-fs-loader', {
    'ea-fs-loader--show': showLoader
  });
  const circle1Classes = classnames('ea-fs-loader__circle-1', {
    'ea-fs-loader__circle-1--small': isSmall
  });
  const circle2Classes = classnames('ea-fs-loader__circle-2', {
    'ea-fs-loader__circle-2--small': isSmall
  });

  return (
    <div className={loaderClasses}>
      <div className={circle1Classes} />
      <div className={circle2Classes} />
    </div>
  );
};

FullscreenLoader.defaultProps = {
  isSmall: false
};

FullscreenLoader.propTypes = {
  isSmall: bool
};

export default FullscreenLoader;
