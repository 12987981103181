import { arrayOf, node, oneOfType } from 'prop-types';
import React from 'react';

import './MainSection.scss';

const MainSection = ({ children }) => (
  <div className="ea-layout__main-section">{children}</div>
);

MainSection.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
};

export default MainSection;
