import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Map from '../../../features/map';
import './MapSection.scss';
import FilterRegion from '../../../features/filterRegion';
import Timeline from '../../../features/timeline';
import { GlobalStateContext } from '../../../context/GlobalContextProvider';

const MapSection = ({ pageData, regionfilterNames }) => {
  const globalStateContext = useContext(GlobalStateContext);
  const { region } = globalStateContext;
  // don't show timeline for FilterRegion 'Ladestationen' (because we deliver the static ladestationen.json here)
  const showTimeline = Boolean(pageData.meta.initialYear) && region !== 2;
  const showRegionFilter = Boolean(pageData.meta.regionFilters);

  let initialYear;
  if (pageData && pageData.meta && pageData.meta.initialYear) {
    initialYear = pageData.meta.initialYear;
  }

  return (
    <div className="ea-layout__map-section">
      <Map
        initialYear={pageData.meta.initialYear}
        category={pageData.meta.data?.categoryTooltip}
      />
      <div className="ea-layout__map-section-children">
        {showRegionFilter && (
          <FilterRegion
            regionFilters={pageData.meta.regionFilters}
            regionfilterNames={regionfilterNames}
          />
        )}
        {showTimeline && <Timeline initialYear={initialYear} />}
      </div>
    </div>
  );
};

MapSection.propTypes = {
  pageData: PropTypes.object.isRequired,
  regionfilterNames: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default MapSection;
