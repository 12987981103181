import React from 'react';
import { arrayOf, node, oneOfType } from 'prop-types';

import './DimensionConstrain.scss';

const DimensionConstrain = ({ children }) => (
  <div className="ea-layout__dimension-constrain">{children}</div>
);

DimensionConstrain.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
};

export default DimensionConstrain;
