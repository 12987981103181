/* eslint-disable prettier/prettier */
import PATH_URLS from '../../constants/PATH_URLS';
import pathnameUtilities from '../../services/PathnameUtilities';
import formatNumberValue from '../../helper/formatNumberValue';
import {
  UNIT_KW,
  UNIT_MW
} from '../infoboxTable/parser/converter/chargers';

const MapDrawTooltipHelper = {
  createTooltipHtml: (
    pathname,
    geoJson,
    tooltipContent,
    selectedYear,
    region
  ) => {
    if (geoJson.properties.id === 'Eisenach' && selectedYear >= 2021) {
      return _getEisenachTooltipHtml();
    }

    if (!tooltipContent || !tooltipContent.data.length || !tooltipContent.data[0]) {
      return `<h3>${geoJson.properties.id}</h3>
            <ul>
            <li>Bisher keine Regional-Daten verfügbar oder Wert = 0,00</li>
            </ul>`;
    }

    if (pathname === PATH_URLS.GRUENE_ENERGIEMEISTER.MEISTER_INDEX) {
      const headline = tooltipContent.data[0].name || geoJson.properties.id;
      return `<h3>${headline}</h3>
        <ul>
        <li>Indexwert: ${tooltipContent.data[0].values.masterIndex}</li>
        <li>Ranking: ${tooltipContent.data[0].values.ranking}</li>
        </ul>`;
    }

    if (pathnameUtilities.isAnyEEAnlagenpage(pathname)) {
      return _getEEAnlagenTooltipHtml(pathname, geoJson, tooltipContent);
    }

    if (pathname === PATH_URLS.GRUENE_ENERGIEMEISTER.OEKOSTROMNUTZER) {
      const headline = tooltipContent.data[0].name || geoJson.properties.id;
      return `<h3>${headline}</h3>
        <ul>
        <li>Ökostromnutzung: ${tooltipContent.data[0].values.percentageGreenEnergyUsage.toFixed(
        1
      )}%</li>
        </ul>`;
    }

    if (pathname === PATH_URLS.GRUENE_ENERGIEMEISTER.PATENTE) {
      const headline = tooltipContent.data[0].name || geoJson.properties.id;
      return `<h3>${headline}</h3>    
        <ul>
        <li>Patente: ${tooltipContent.data[0].values.amount}</li>
        <li>pro 100.000 Einwohner: ${formatNumberValue(
        tooltipContent.data[0].values.perHundredK,
        2
      )}</li>
        </ul>`;
    }

    if (pathname === PATH_URLS.GRUENE_ENERGIEMEISTER.ENERGIEMEISTER_LADESTATIONEN) {
      const headline = tooltipContent.data[0].name || geoJson.properties.id;
      return `<h3>${headline}</h3>
        <ul>
        <li>Anzahl: ${formatNumberValue(
        tooltipContent.data[0].values.amount,
        0
      )}</li>
        <li>Ladestationen pro 100km<sup>2</sup>: ${formatNumberValue(
        tooltipContent.data[0].values.perHundredSquareKilometers
      )}</li>
        </ul>`;
    }

    if (pathname === PATH_URLS.E_MOBILITY.E_AUTO_BESITZER) {
      const headline = tooltipContent.data[0].name || geoJson.properties.id;
      const { values } = tooltipContent.data[0];
      const {
        amount,
        co2SavingsGreenElectricity,
        co2SavingsMixedElectricity
      } = values;

      return `<h3>${headline}</h3>
        <ul>
          <li>Elektroautos: ${formatNumberValue(amount, 0)}</li>
          ${
            co2SavingsMixedElectricity
              ? `<li>CO<sub>2</sub>-Einsparung/Jahr (E-Autos geladen mit Strommix): ${formatNumberValue(
                  co2SavingsMixedElectricity,
                  0,
                  2
                )}&nbsp;t</li>`
              : ''
          }
          ${
            co2SavingsGreenElectricity
              ? `<li>CO<sub>2</sub>-Einsparung/Jahr (E-Autos geladen mit 100% Ökostrom): ${formatNumberValue(
                  co2SavingsGreenElectricity,
                  0,
                  2
                )}&nbsp;t</li>`
              : ''
          }
        </ul>`;
    }

    if (pathname === PATH_URLS.E_MOBILITY.LADESTATIONEN) {
      const headline = tooltipContent.data[0].name || geoJson.properties.id;
      const {
        points,
        stations,
        totalPowerRatingMW,
        totalPowerRatingKWPer1000
      } = tooltipContent.data[0].values;

      let totalPowerRating = totalPowerRatingMW;
      if (totalPowerRating) {
        if (region === 'districts') {
          totalPowerRating = `${formatNumberValue(
            totalPowerRating * 1000,
            0,
            0
          )}&nbsp;${UNIT_KW}`;
        } else {
          totalPowerRating = `${formatNumberValue(
            totalPowerRating
          )}&nbsp;${UNIT_MW}`;
        }
      }

      const totalPowerRatingPerCar =
        totalPowerRatingKWPer1000 &&
        `${formatNumberValue(totalPowerRatingKWPer1000)}&nbsp;${UNIT_KW}`;

      return `<h3>${headline}</h3>
      <ul>
      ${
        points && points > 0
          ? `<li>Ladepunkte: ${formatNumberValue(points, 0)}</li>`
          : ''
      }
      ${
        stations
          ? `<li>Ladestationen: ${formatNumberValue(stations, 0)}</li>`
          : ''
      }
      ${
        totalPowerRating
          ? `<li>Gesamte Ladeleistung: ${totalPowerRating}</li>`
          : ''
      }
      ${
        totalPowerRatingKWPer1000
          ? `<li>Ladeleistung pro E-Auto: ${totalPowerRatingPerCar}</li>`
          : ''
      }
      </ul>`;
    }

    if (pathname === PATH_URLS.E_MOBILITY.E_BIKES) {
      if (region === 'states') {
        const headline = tooltipContent.data[0].name || geoJson.properties.id;
        const greenEnergyHeadline =
          selectedYear === 2021
            ? 'Ökostrom für geplantes E-Bike'
            : 'Laden mit Ökostrom';

        return `<h3>${headline}</h3>
        <ul>
          ${tooltipContent.data[0].values.owners
            ? `<li>E-Bike-Besitzer: ${formatNumberValue(
              tooltipContent.data[0].values.owners,
              0
            )}%</li>`
            : ''
          }
          ${tooltipContent.data[0].values.potential
            ? `<li>E-Bike Potential: ${formatNumberValue(
              tooltipContent.data[0].values.potential,
              0
            )}%</li>`
            : ''
          }
          ${tooltipContent.data[0].values.greenEnergy
            ? `<li>${greenEnergyHeadline}: ${formatNumberValue(
              tooltipContent.data[0].values.greenEnergy,
              0
            )}%</li>`
            : ''
          }
          ${tooltipContent.data[0].values.highUsage
            ? `<li>Vielfahrer (≥ 200km/Mon.): ${formatNumberValue(
              tooltipContent.data[0].values.highUsage,
              0
            )}%</li>`
            : ''
          }
        </ul>`;
      }
      const headline = tooltipContent.data[0].name || geoJson.properties.id;
      return `<h3>${headline}</h3>
      <ul>
      <li>E-Bike-Besitzer: ${formatNumberValue(
        tooltipContent.data[0].values.owners,
        0
      )}%</li>
      </ul>`;
    }

    if (pathname === PATH_URLS.HAUSHALT.STREAMING) {
      const headline = tooltipContent.data[0].name || geoJson.properties.id;
      const percentageFrequentStreamers = formatNumberValue(
        tooltipContent.data[0].values.percentageFrequentStreamers,
        1,
        1
      );
      const percentagePreferGreenProvider = formatNumberValue(
        tooltipContent.data[0].values.percentagePreferGreenProvider,
        1,
        1
      );

      if (region !== 'districts') {
        return `<h3>${headline}</h3>
        <ul>
        <li>Streaming-Nutzer: ${percentageFrequentStreamers}%</li>
        <li>Nachhaltig orientiert: ${percentagePreferGreenProvider}%</li>
        </ul>`;
      }

      return `<h3>${headline}</h3>
        <ul>
        <li>Streaming-Nutzer: ${percentageFrequentStreamers}%</li>
        </ul>`;
    }

    if (pathname.includes(PATH_URLS.ZUKUNFTSINDEX_PART)) {
      const { headline = geoJson.properties.id, values } = tooltipContent.data[0];
      let listItems;

      switch (pathname) {
        case (PATH_URLS.ZUKUNFTSINDEX.CO2_EINSPARUNG_JAHR_GEPLANT):
          listItems = `
            <li>Geplante CO₂-Einsparung/Jahr: ${formatNumberValue(values.co2SavingsPlanned, 0, 0)} t</li>
            <li>Energiewende Zuhause: ${formatNumberValue(values.energyChangeAtHome, 0, 0)} t</li>
            <li>Verkehrswende: ${formatNumberValue(values.transportChange, 0, 0)} t</li>
            <li>Verbrauchswende: ${formatNumberValue(values.consumptionChange, 0, 0)} t</li>
            <li>Max. CO₂-Sparpotenzial: ${formatNumberValue(values.generalCo2SavingsPotential, 0, 0)} t</li>
          `;
          break;
        case (PATH_URLS.ZUKUNFTSINDEX.DURCH_ENERGIEWENDE_ZUHAUSE.GESAMT):
          listItems = `
            <li>Geplante CO₂-Einsparung/Jahr: ${formatNumberValue(values.energyChangeAtHome, 0, 0)} t</li>
            <li>Dach-Solaranlage: ${formatNumberValue(values.installmentsRoofSolar, 0, 0)} t</li>
            <li>Balkon-Solaranlage: ${formatNumberValue(values.installmentsBalconySolar, 0, 0)} t</li>
            <li>Effiziente Heizanlage: ${formatNumberValue(values.efficientHeating, 0, 0)} t</li>
            <li>Max. CO₂-Sparpotenzial: ${formatNumberValue(values.homeCo2SavingsPotential, 0, 0)} t</li>
          `;
          break;
        case (PATH_URLS.ZUKUNFTSINDEX.DURCH_ENERGIEWENDE_ZUHAUSE.INSTALLATION_DACH_SOLARANLAGEN):
        case (PATH_URLS.ZUKUNFTSINDEX.DURCH_ENERGIEWENDE_ZUHAUSE.INSTALLATION_BALKON_SOLARANLAGE):
        case (PATH_URLS.ZUKUNFTSINDEX.DURCH_ENERGIEWENDE_ZUHAUSE.EFFIZIENTE_HEIZANALGE):
          listItems = `
            <li>Geplante CO₂-Einsparung/Jahr: ${formatNumberValue(values.energyChangeAtHome, 0, 0)} t</li>
            <li>Dach-Solaranlage: ${formatNumberValue(values.installmentsRoofSolar, 0, 0)} t</li>
            <li>Balkon-Solaranlage: ${formatNumberValue(values.installmentsBalconySolar, 0, 0)} t</li>
            <li>Effiziente Heizanlage: ${formatNumberValue(values.efficientHeating, 0, 0)} t</li>
          `;
          break;
        case (PATH_URLS.ZUKUNFTSINDEX.DURCH_VERKEHRSWENDE.GESAMT):
          listItems = `
            <li>Geplante CO₂-Einsparung/Jahr: ${formatNumberValue(values.transportChange, 0, 0)} t</li>
            <li>Umstieg E-Auto: ${formatNumberValue(values.switchToElectricVehicle, 0, 0)} t</li>
            <li>Umstieg ÖPNV: ${formatNumberValue(values.switchToPublicTransport, 0, 0)} t</li>
            <li>Bidirektionales Laden: ${values.bidirectionalCharging === 0 ? '-' : `${formatNumberValue(values.bidirectionalCharging, 0, 0)} t`}</li>
            <li>Max. CO₂-Sparpotenzial: ${formatNumberValue(values.transportCo2SavingsPotential, 0, 0)} t</li>
          `;  
          break;
        case (PATH_URLS.ZUKUNFTSINDEX.DURCH_VERKEHRSWENDE.UMSTIEG_E_AUTO):
        case (PATH_URLS.ZUKUNFTSINDEX.DURCH_VERKEHRSWENDE.UMSTIEG_OEPNV):
        case (PATH_URLS.ZUKUNFTSINDEX.DURCH_VERKEHRSWENDE.BIDIREKTIONALES_LADEM):
          listItems = `
            <li>Geplante CO₂-Einsparung/Jahr: ${formatNumberValue(values.transportChange, 0, 0)} t</li>
            <li>Umstieg E-Auto: ${formatNumberValue(values.switchToElectricVehicle, 0, 0)} t</li>
            <li>Umstieg ÖPNV: ${formatNumberValue(values.switchToPublicTransport, 0, 0)} t</li>
            <li>Bidirektionales Laden: ${values.bidirectionalCharging === 0 ? '-' : `${formatNumberValue(values.bidirectionalCharging, 0, 0)} t`}</li>
          `;  
          break;
        case (PATH_URLS.ZUKUNFTSINDEX.DURCH_VERBRAUCHSWENDE.GESAMT):
          listItems = `
            <li>Geplante CO₂-Einsparung/Jahr: ${formatNumberValue(values.consumptionChange, 0, 0)} t</li>
            <li>Heiztemperatur senken: ${formatNumberValue(values.lowerHeatingTemperature, 0, 0)} t</li>
            <li>Smarte Thermostate: ${formatNumberValue(values.smartThermostat, 0, 0)} t</li>
            <li>Max. CO₂-Sparpotenzial: ${formatNumberValue(values.consumptionCo2SavingsPotential, 0, 0)} t</li>
          `;  
          break;
        case (PATH_URLS.ZUKUNFTSINDEX.DURCH_VERBRAUCHSWENDE.HEIZTEMERATUR_SENKEN):
        case (PATH_URLS.ZUKUNFTSINDEX.DURCH_VERBRAUCHSWENDE.SMARTE_THERMOSTATE):
          listItems = `
            <li>Geplante CO₂-Einsparung/Jahr: ${formatNumberValue(values.consumptionChange, 0, 0)} t</li>
            <li>Heiztemperatur senken: ${formatNumberValue(values.lowerHeatingTemperature, 0, 0)} t</li>
            <li>Smarte Thermostate: ${formatNumberValue(values.smartThermostat, 0, 0)} t</li>
          `;  
          break;
        default:
          listItems = null;
      }

      if (listItems !== null) {
        return `
          <h3>${headline}</h3>
          <ul>${listItems}</ul>
        `
      }
    }

    return `<h3>No matching Tooltip found</h3>`;
  }
};

function _getEisenachTooltipHtml() {
  return `
    <p>Die zuvor kreisfreie Stadt Eisenach gehört<br /> seit 1. Juli 2021 zum Wartburgkreis.</p>
  `;
}

function _getEEAnlagenTooltipHtml(pathname, geoJson, tooltipContent) {
  const tooltipContentValues = tooltipContent.data[0].values;
  const tooltipMetaData = tooltipContent.metaData;

  let netOutputArea;
  switch (pathname) {
    case PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.GESAMT:
      netOutputArea = tooltipContentValues.netOutputPerArea;
      break;
    case PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.WIND:
      netOutputArea = tooltipContentValues.netWindPerArea;
      break;
    case PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.SOLAR:
      netOutputArea = tooltipContentValues.netSolarPerArea;
      break;
    case PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.WASSERKRAFT:
      netOutputArea = tooltipContentValues.netWaterPerArea;
      break;
    case PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.BIOMASSE:
      netOutputArea = tooltipContentValues.netBiomassPerArea;
      break;
    default:
      netOutputArea = '-';
  }

  const headline = tooltipContent.data[0].name || geoJson.properties.id;
  return `<h3>${headline}</h3>
  <ul>
  <li class="leaflet-tooltip-li--active">Gesamt kW/km&sup2: ${formatNumberValue(
    netOutputArea
  )}</li>
  <li class="${getActiveClass(
    pathname,
    PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.GESAMT
  )}">Gesamt: ${_addUnit(
    formatNumberValue(tooltipContentValues.netOutput),
    tooltipMetaData.unit
  )}</li>
  <li class="${getActiveClass(
    pathname,
    PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.WIND
  )}">Wind: ${formatNumberValue(tooltipContentValues.netWind)} ${tooltipMetaData.unit
    }</li>
  <li class="${getActiveClass(
      pathname,
      PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.SOLAR
    )}">Solar: ${formatNumberValue(tooltipContentValues.netSolar)} ${tooltipMetaData.unit
    }</li>
  <li class="${getActiveClass(
      pathname,
      PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.WASSERKRAFT
    )}">Wasserkraft: ${formatNumberValue(tooltipContentValues.netWater)} ${tooltipMetaData.unit
    }</li>
  <li class="${getActiveClass(
      pathname,
      PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.BIOMASSE
    )}">Biomasse: ${formatNumberValue(tooltipContentValues.netBiomass)} ${tooltipMetaData.unit
    }</li>
  </ul>`;
}

function getActiveClass(currentPathname, path) {
  if (path === currentPathname) {
    return 'leaflet-tooltip-li--active';
  }
  return '';
}

function _addUnit(value, unit) {
  if (!value) {
    return '-';
  }

  return `${value} ${unit}`;
}

export default MapDrawTooltipHelper;
