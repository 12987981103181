class ResponsiveHelperService {
  constructor() {
    this.tabletWidth = 768;
    this.desktopWidth = 1200;
    this.desktopHeight = 730;
  }

  isMinWidthDesktop() {
    return window.innerWidth >= this.desktopWidth;
  }

  isMinHeight() {
    return window.innerHeight >= this.desktopHeight;
  }
}
module.exports = new ResponsiveHelperService();
