import { any, node, shape, string } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import Helmet from 'react-helmet';
import { FeatureFlagsProvider } from '../../context/FeatureFlagsProvider';
import {
  BATCH_UPDATE_INITIAL_VALUES,
  GlobalDispatchContext,
  GlobalStateContext
} from '../../context/GlobalContextProvider';
import Footer from '../../features/footer';
import FullscreenLoader from '../../features/fullscreenLoader';
import Tabnavigation from '../../features/tabnavigation';

import Header from '../Header';
import Topline from '../Topline/Topline';
import DimensionConstrain from './DimensionConstrain/DimensionConstrain';
import './Layout.scss';
import MainSection from './MainSection/MainSection';
import MapSection from './MapSection/MapSection';
import TopSection from './TopSection/TopSection';
import { isIframe } from '../../helper/iframeHelper';

const Layout = ({ children, pageContext, location }) => {
  const globalDispatch = useContext(GlobalDispatchContext);
  const { year, region, fallbackYear, fullyAcceptedCookies } =
    useContext(GlobalStateContext);

  const scriptTags = !isIframe() ? (
    <Helmet>
      {fullyAcceptedCookies && (
        <script src="https://www.google-analytics.com/analytics.js" async />
      )}
      <script
        src="https://www.googletagmanager.com/gtm.js?id=GTM-PS8GWHD"
        async
      />
      <script async>
        {`(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);    })(window,document,'script','dataLayer','GTM-W8LHPX4');    (function(w,d,s,l,i,c){w[l] = w[l] || [];var f=d.getElementsByTagName(s)[0],    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= c +'?id='+i+dl;    f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-NDBX2F4',    'https://server.eon.de/gtm.js');`}
      </script>
    </Helmet>
  ) : null;

  const flags = pageContext.featureFlags;

  const { pageData } = pageContext;

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(pageData.pathWithNewestStory);
    }
  }, [location, pageData]);

  useEffect(() => {
    const initialValues = {};
    const pageDataDefaults = {
      hasRegion: Boolean(pageData.meta.regionFilters),
      hasYear: Boolean(pageData.meta.initialYear),
      hasFallbackYear: Boolean(pageData.meta.fallbackYear),
      defaultRegion: 0,
      defaultYear: pageData.meta.initialYear,
      fallbackYear: pageData.meta.fallbackYear
    };

    if (pageDataDefaults.hasRegion && region === null) {
      initialValues.region = pageDataDefaults.defaultRegion;
    }

    if (!pageDataDefaults.hasRegion && region !== null) {
      initialValues.region = null;
    }

    if (pageDataDefaults.hasYear && year === null) {
      initialValues.year = pageDataDefaults.defaultYear;
    }

    if (!pageDataDefaults.hasYear && year !== null) {
      initialValues.year = null;
    }

    if (pageDataDefaults.hasFallbackYear && fallbackYear === null) {
      initialValues.fallbackYear = pageDataDefaults.fallbackYear;
    }

    if (!pageDataDefaults.hasFallbackYear && fallbackYear !== null) {
      initialValues.fallbackYear = null;
    }

    if (Object.keys(initialValues).length) {
      globalDispatch({
        type: BATCH_UPDATE_INITIAL_VALUES,
        payload: initialValues
      });
    }
  });

  if (!pageData) {
    return null;
  }

  if (pageContext.layout === '404') {
    return children;
  }

  return (
    <FeatureFlagsProvider flags={flags}>
      <div className="ea-layout__container">
        {scriptTags}
        <FullscreenLoader />
        <TopSection>
          <Topline />
          <DimensionConstrain>
            <Header
              siteTitle={pageData.cmsData.basicInfo.siteTitle}
              logoHref={pageData.cmsData.basicInfo.logoHref}
              pathWithNewestStory={pageData.pathWithNewestStory}
            />
          </DimensionConstrain>
          <Tabnavigation
            tabnaviConfig={pageData.cmsData.tabnaviConfig}
            pathname={location.pathname}
            pageRenderPath={pageData.path}
          />
        </TopSection>
        <MainSection>
          {children}
          <MapSection
            pageData={pageData}
            regionfilterNames={pageData.cmsData.regionfilterNames}
          />
        </MainSection>
        <Footer
          footerItems={pageData.cmsData.footer}
          onCookieLinkClick={() => {
            if (typeof UC_UI !== 'undefined') {
              // eslint-disable-next-line
              UC_UI.showSecondLayer();
            }
          }}
        />
      </div>
    </FeatureFlagsProvider>
  );
};

/* eslint-disable react/forbid-prop-types */
Layout.propTypes = {
  pageContext: any.isRequired,
  children: node.isRequired,
  location: shape({
    pathname: string
  }).isRequired
};
/* eslint-enable react/forbid-prop-types */

export default Layout;
