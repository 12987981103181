import React from 'react';

import PropTypes from 'prop-types';
import DimensionConstrain from '../../../shared-components/Layout/DimensionConstrain/DimensionConstrain';
import './Footer.scss';

const Footer = ({ footerItems, onCookieLinkClick }) => (
  <div className="ea-footer-container">
    <DimensionConstrain>
      <footer className="ea-footer">
        <ul className="ea-footer-list">
          {footerItems.map((item) => {
            return (
              <li key={item.text} className="ea-footer-list__item">
                <a
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ea-footer-list__link"
                >
                  {item.text}
                </a>
              </li>
            );
          })}
          {onCookieLinkClick && (
            <li className="ea-footer-list__item">
              <button
                type="button"
                className="ea-footer-list__link"
                onClick={onCookieLinkClick}
              >
                Cookies
              </button>
            </li>
          )}
        </ul>
      </footer>
    </DimensionConstrain>
  </div>
);

Footer.propTypes = {
  footerItems: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string,
      href: PropTypes.string
    })
  ).isRequired,
  onCookieLinkClick: PropTypes.func
};

Footer.defaultProps = {
  onCookieLinkClick: null
};

export default Footer;
