import classnames from 'classnames';
import { any, object, arrayOf, string } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { Link } from 'gatsby';
import { isIframe } from '../../../helper/iframeHelper';

import './FitlerRegion.scss';
import {
  GlobalDispatchContext,
  GlobalStateContext,
  SET_REGION_FILTER
} from '../../../context/GlobalContextProvider';
import withLocation from '../../../HOCs/withLocation/withLocation';

/* CONSTANTS
=========================================================================== */

export const DEFAULT_FILTER = 0;

/* Create filter buttons
=========================================================================== */
const createFilterButtons = ({
  regionFilters,
  filtersNames,
  activeFilter,
  location,
  globalDispatch
}) => {
  return regionFilters.map((regionFilter) => {
    const isFirstFilter = regionFilter === 0;
    const isLastFilter = regionFilter === regionFilters.length - 1;
    const isActive = activeFilter === regionFilter;

    const className = classnames('ea-filter-region__button', {
      'ea-filter-region__button--first': isFirstFilter,
      'ea-filter-region__button--last': isLastFilter,
      'ea-filter-region__button--active': isActive
    });

    const href = isIframe()
      ? location.pathname + window.location.search
      : location.pathname;

    const filterName = filtersNames[regionFilter];
    return (
      <Link
        to={href}
        key={`filter--${filterName}`}
        className={className}
        onClick={
          () =>
            globalDispatch({ type: SET_REGION_FILTER, payload: regionFilter })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      >
        {filterName}
      </Link>
    );
  });
};

/* Component
=========================================================================== */
const FilterRegion = ({
  search,
  location,
  regionFilters,
  regionfilterNames
}) => {
  const globalDispatch = useContext(GlobalDispatchContext);
  const globalStateContext = useContext(GlobalStateContext);

  useEffect(() => {
    const lastConfiguredRegion = regionFilters[regionFilters.length - 1];
    if (globalStateContext.region > lastConfiguredRegion) {
      globalDispatch({
        type: SET_REGION_FILTER,
        payload: lastConfiguredRegion
      });
    }
  });

  const filterButtons = createFilterButtons({
    regionFilters,
    filtersNames: regionfilterNames,
    activeFilter: globalStateContext.region || DEFAULT_FILTER,
    search,
    location,
    globalDispatch
  });

  return (
    <div className="ea-filter-region-container">
      <div className="ea-filter-region-container-inner">{filterButtons}</div>
    </div>
  );
};

FilterRegion.propTypes = {
  location: any.isRequired,
  search: object.isRequired,
  regionFilters: any.isRequired,
  regionfilterNames: arrayOf(string).isRequired
};

export default withLocation(FilterRegion);
