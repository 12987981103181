function formatNumberValue(
  value,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) {
  if (value === undefined || value === null) {
    return '-';
  }

  return new Intl.NumberFormat('de-DE', {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits
  }).format(value);
}

export default formatNumberValue;
