function sortData(data, key, desc = true) {
  data.sort((a, b) => {
    const aValue = a.values[key] || 0;
    const bValue = b.values[key] || 0;

    return aValue - bValue;
  });

  if (desc) {
    // Sort descending
    data.reverse();
  }

  return data;
}

export default sortData;
