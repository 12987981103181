const prefix = 'eonea';

class LocalStorage {
  get(key) {
    const data = sessionStorage.getItem(`${prefix}[${key}]`);

    if (!data) return null;

    return JSON.parse(data);
  }

  set(key, value) {
    sessionStorage.setItem(`${prefix}[${key}]`, JSON.stringify(value));
  }
}

export default LocalStorage;
