import { arrayOf, node, oneOfType } from 'prop-types';
import React from 'react';

const TopSection = ({ children }) => (
  <header className="ea-header__top-section">{children}</header>
);

TopSection.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
};

export default TopSection;
