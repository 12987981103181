import pathnameUtilities from '../../services/PathnameUtilities';

const timelineHelper = {
  createSliderOptions: (year, availableYears, currentPathname) => {
    if (!availableYears || typeof availableYears === 'string') {
      return { marks: [] };
    }

    const marks = availableYears.map((availableYear, index) => {
      const label = _getLabel(
        availableYear,
        availableYears.length,
        index,
        currentPathname
      );

      const mark = { value: availableYear };
      if (label) {
        mark.label = `${label}`;
      }
      return mark;
    });

    return {
      marks,
      defaultValue: year,
      min: availableYears[0],
      max: availableYears[availableYears.length - 1]
    };
  },

  createSliderBubbleLabel: (mark, currentPathname) => {
    if (
      pathnameUtilities.isEAutoBesitzerPage(currentPathname) ||
      pathnameUtilities.isLadestationenPage(currentPathname)
    ) {
      return _convertYearToDateString(mark)
        .replace('01.01.', '01.01. ')
        .replace('01.10.', '01.10. ');
    }

    return mark;
  }
};

// eslint-disable-next-line no-underscore-dangle
function _getLabel(year, availableYearsLength, index, currentPathname) {
  // Wonderful *_*

  let modulo;
  if (availableYearsLength < 20) {
    modulo = 2;
  } else if (availableYearsLength < 28) {
    modulo = 4;
  } else {
    modulo = 5;
  }

  let label = null;
  if (availableYearsLength <= 4) {
    label = `${year}`;
  } else if (index % modulo === 0 || index === availableYearsLength - 1) {
    const isOddAndSecondButLastYear =
      availableYearsLength % 2 === 0 && index === availableYearsLength - 2;
    if (isOddAndSecondButLastYear) {
      label = null;
    } else {
      label = `${year}`;
    }
  }

  if (
    label &&
    (pathnameUtilities.isEAutoBesitzerPage(currentPathname) ||
      pathnameUtilities.isLadestationenPage(currentPathname))
  ) {
    return _convertYearToDateString(year);
  }

  return label;
}

function _convertYearToDateString(year) {
  const yearString = `${year}`;
  const yearSubstring = yearString.substring(2);

  if (year === 2023) {
    return `01.10.${yearSubstring}`;
  }

  return `01.01.${yearSubstring}`;
}

export default timelineHelper;
