import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import EonLogoSvg from '../../../assets/icons/logo-red.svg';

import './Header.scss';

const Header = ({ siteTitle, logoHref, pathWithNewestStory }) => (
  <header className="ea-header">
    <div className="ea-header__content">
      <h1 className="ea-header__heading">
        <Link className="ea-header__heading-link" to={pathWithNewestStory}>
          {siteTitle}
        </Link>
      </h1>

      <a href={logoHref} target="_blank" rel="noopener noreferrer">
        <img className="ea-header__logo" src={EonLogoSvg} alt="E.ON Logo" />
      </a>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  logoHref: PropTypes.string,
  pathWithNewestStory: PropTypes.string
};

Header.defaultProps = {
  siteTitle: '',
  logoHref: '',
  pathWithNewestStory: '/'
};

export default Header;
