/* eslint-disable */
import markerSvgBluey from '../../assets/icons/Pin_MitDaten.svg';
import markerSvgRed from '../../assets/icons/Pin_MitDaten-red.svg';
import maskerSvgLadestation from '../../assets/icons/Pin_Ladestationen.svg';
import PubSub from 'pubsub-js';
import CONSTANTS from '../../constants';
import PATH_URLS from '../../constants/PATH_URLS';
import mapDrawTooltipHelper from './MapDrawTooltipHelper';

export const REGIONS = {
  STATES: 'states',
  DISTRICS: 'districts'
};

class MapDrawService {
  constructor(mapApi, map, selectedYear, category) {
    this.tooltipOptions = {
      direction: 'top',
      opacity: 0.9
    };

    if (typeof window !== 'undefined') {
      this.L = require('leaflet');
      require('leaflet.markercluster/dist/leaflet.markercluster');
    }
    this.mymap = map;

    this.colorMap = {
      1: '#1EA2B1',
      2: '#4BB5C1',
      3: '#6DC3CC',
      4: '#9AD5DC',
      5: '#C7E8EB'
    };

    this.fillColorHover = '#E3E003';

    this.mapApi = mapApi;
    this.category = category;
    this.selectedYear = selectedYear;
    this.currentPathname = null;

    this.renderedPolygonsWithGeoJson = [];
    this.renderedMarkers = [];
    this.renderedMarkerClusterGroup = {};
  }

  renderGeoJsonsPolygons(region, geoJsonsWithRang) {
    geoJsonsWithRang.features.forEach(geoJson => {
      if (
        geoJson.geometry.type === 'Polygon' ||
        geoJson.geometry.type === 'MultiPolygon'
      ) {
        this._renderPolygon(region, geoJson);
      }
    });
  }

  renderGeoJsonsMarkers(pathname, geoJsonsWithRang) {
    this.renderedMarkerClusterGroup = this._createMarkerGroup();

    geoJsonsWithRang.features.forEach(geoJson => {
      if (geoJson.geometry.type === 'Point') {
        let marker;

        switch (pathname) {
          case PATH_URLS.E_MOBILITY.LADESTATIONEN:
            marker = this._createLadestationMarker(geoJson);
            break;
          default:
            marker = this._createCityMarker(geoJson);
        }

        this.renderedMarkers.push({ marker, geoJson });
        this.renderedMarkerClusterGroup.addLayer(marker);
      }
    });

    this.mymap.addLayer(this.renderedMarkerClusterGroup);
  }

  clearMap() {
    this.renderedPolygonsWithGeoJson.forEach(({ polygon }) => {
      polygon.remove();
    });
    this.renderedPolygonsWithGeoJson = [];

    this.renderedMarkers = [];
    this.mymap.removeLayer(this.renderedMarkerClusterGroup);
    this.renderedMarkerClusterGroup = {};
  }

  resetAllTooltipsForPolygon(region) {
    this.renderedPolygonsWithGeoJson.forEach(({ polygon, geoJson }) => {
      polygon.closeTooltip();
      polygon.unbindTooltip();

      polygon.off('mouseover');
      polygon.off('mouseout');

      this._addTooltipForPolygon(region, geoJson, polygon);

      this._addHoverForPolygon(geoJson, polygon);
    });
  }

  updateRankOfPolygons(ranks) {
    if (!ranks) {
      this.renderedPolygonsWithGeoJson.forEach(({ polygon }) => {
        polygon.setStyle({ fillColor: '#fff', color: '#eee' });
      });
      return;
    }

    this.renderedPolygonsWithGeoJson.forEach(({ polygon, geoJson }) => {
      const foundRank = ranks.find(rank => {
        return rank.ags == geoJson.properties.ags;
      });

      if (foundRank) {
        polygon.setStyle({
          fillColor: this.colorMap[foundRank.rank] || '#fff',
          color: '#fff'
        });
      } else {
        polygon.setStyle({ fillColor: '#fff', color: '#eee' });
      }
    });
  }

  updateRankOfMarkers() {
    // TODO
    // Maybe useful:
    /*
    console.log(this.renderedMarkerClusterGroup);

    const layers = this.renderedMarkerClusterGroup.getLayers();
    console.log(layers.length);
    this.renderedMarkerClusterGroup.removeLayers(layers);
    this.renderedMarkerClusterGroup.addLayers(layers);
    */
  }

  setCategory(category) {
    this.category = category;
  }

  setSelectedYear(selectedYear) {
    this.selectedYear = selectedYear;
  }

  setCurrentPathname(currentPathname) {
    this.currentPathname = currentPathname;
  }

  setMapApi(mapApi) {
    this.mapApi = mapApi;
  }

  _renderPolygon(region, geoJson) {
    const latlngs = geoJson.geometry.coordinates;
    const polygon = this.L.polygon(latlngs, {
      color: '#eee',
      weight: 1,
      fillColor: this.colorMap[geoJson.properties.rank] || '#fff',
      opacity: 0.7,
      fillOpacity: 1.0
    }).addTo(this.mymap);
    this.renderedPolygonsWithGeoJson.push({ polygon, geoJson });

    this._addTooltipForPolygon(region, geoJson, polygon);
    this._addHoverForPolygon(geoJson, polygon);
  }

  _addHoverForPolygon(geoJson, polygon) {
    let previousColor;
    let previousFillColor;

    polygon.on('mouseover', e => {
      previousColor = e.target.options.color;
      previousFillColor = e.target.options.fillColor;
      polygon.setStyle({ fillColor: this.fillColorHover, color: '#fff' });

      PubSub.publish(
        CONSTANTS.PUBSUB.TOPIC_HOVER_MAP_ELEMENT,
        geoJson.properties.ags
      );
    });

    polygon.on('mouseout', e => {
      if (!previousFillColor) {
        return;
      }

      polygon.setStyle({ fillColor: previousFillColor, color: previousColor });

      PubSub.publish(CONSTANTS.PUBSUB.TOPIC_HOVER_MAP_ELEMENT, '');
    });
  }

  _addTooltipForPolygon(region, geoJson, polygon) {
    polygon.bindTooltip(
      `<h3>${geoJson.properties.id}</h3>
      <div class="tooltip-loader">Loading...</div>`,
      this.tooltipOptions
    );

    let mouseOnPolygon = false;

    polygon.on('mouseover', async e => {
      mouseOnPolygon = true;
      if (!polygon.getTooltip()._content.includes('loader')) {
        return;
      }

      const tooltipContent = await this.mapApi.fetchTooltip(
        region,
        geoJson.properties.ags,
        this.selectedYear,
        this.category
      );

      const tooltipTextHTML = mapDrawTooltipHelper.createTooltipHtml(
        this.currentPathname,
        geoJson,
        tooltipContent,
        this.selectedYear,
        region
      );

      polygon.unbindTooltip();
      polygon.bindTooltip(tooltipTextHTML, this.tooltipOptions);
      if (mouseOnPolygon) {
        polygon.openTooltip();
      }
    });

    polygon.on('mouseout', e => {
      mouseOnPolygon = false;
    });
  }

  _createCityMarker(geoJson) {
    const BasicMarkerIcon = this._createBasicMarkerIcon();
    const markerIconBluey = new BasicMarkerIcon({ iconUrl: markerSvgBluey });
    const markerIconRed = new BasicMarkerIcon({ iconUrl: markerSvgRed });

    const marker = this.L.marker(
      {
        lon: geoJson.geometry.coordinates[0],
        lat: geoJson.geometry.coordinates[1]
      },
      {
        icon: markerIconBluey
      }
    );
    this._addTooltipForMarker(geoJson, marker);
    this._addHoverForMarker(marker, markerIconBluey, markerIconRed);
    return marker;
  }

  _addTooltipForMarker(geoJson, marker) {
    marker.bindTooltip(
      `<h3>${geoJson.properties.id}</h3>
    <div class="tooltip-loader">Loading...</div>`,
      this.tooltipOptions
    );

    let mouseOnMarker = false;

    marker.on('mouseover', async e => {
      mouseOnMarker = true;
      if (!marker.getTooltip()._content.includes('loader')) {
        return;
      }

      const tooltipContent = await this.mapApi.fetchTooltip(
        geoJson.properties.ags,
        this.selectedYear,
        this.category
      );

      let tooltipTextHTML;
      if (tooltipContent) {
        // Use MapDrawTooltipHelper, when cities are back :)
        // See tooltip stuff for polygon
        tooltipTextHTML = `<h3>${geoJson.properties.id}</h3>
          <ul>
          <li>Patente: ${tooltipContent.value[0].amount}</li>
          </ul>`;
      } else {
        tooltipTextHTML = `<h3>${geoJson.properties.id}</h3>
        <ul>
        <li>Bisher keine Regional-Daten verfügbar oder Wert = 0,00</li>
        </ul>`;
      }

      marker.unbindTooltip();
      marker.bindTooltip(tooltipTextHTML, this.tooltipOptions);
      if (mouseOnMarker) {
        marker.openTooltip();
      }
    });

    marker.on('mouseout', e => {
      mouseOnMarker = false;
    });
  }

  _addHoverForMarker(marker, markerIconBluey, markerIconRed) {
    marker.on('mouseover', () => {
      marker.setIcon(markerIconRed);
    });
    marker.on('mouseout', () => {
      marker.setIcon(markerIconBluey);
    });
  }

  _createLadestationMarker(geoJson) {
    const BasicMarkerIcon = this._createBasicMarkerIcon();
    const maskerIconLadestation = new BasicMarkerIcon({
      iconUrl: maskerSvgLadestation
    });

    return this.L.marker(
      {
        lon: geoJson.geometry.coordinates[0],
        lat: geoJson.geometry.coordinates[1]
      },
      {
        icon: maskerIconLadestation
      }
    );
  }

  _createBasicMarkerIcon() {
    return this.L.Icon.extend({
      options: {
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        tooltipAnchor: [0, -35]
      }
    });
  }

  _createMarkerGroup() {
    const markers = this.L.markerClusterGroup({
      showCoverageOnHover: false,
      zoomToBoundsOnClick: false,
      spiderfyOnMaxZoom: false,
      // 13 for example is not performant -> too many markers
      disableClusteringAtZoom: 14,
      maxClusterRadius: mapZoom => {
        if (mapZoom <= 8) {
          return 80;
        }

        return 175;
      }
    });
    markers.on('clusterclick', function (a) {
      // a.layer.zoomToBounds({ padding: [200, 20] });
    });
    return markers;
  }
}

export default MapDrawService;
/* eslint-enable */
