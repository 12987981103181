/* eslint-disable no-underscore-dangle */
const CoordinatesReverser = {
  reverseCoordinatesInGeoJson: (fullGeoJson) => {
    fullGeoJson.features.forEach((geoJson) => {
      if (geoJson.geometry.type === 'Polygon') {
        geoJson.geometry.coordinates =
          CoordinatesReverser._reversePolygonCoordinatesToMatchLeafletApi(
            geoJson.geometry.coordinates
          );
      } else if (geoJson.geometry.type === 'MultiPolygon') {
        geoJson.geometry.coordinates =
          CoordinatesReverser._reverseMultiPolygonCoordinatesToMatchLeafletApi(
            geoJson.geometry.coordinates
          );
      }
    });
  },

  _reversePolygonCoordinatesToMatchLeafletApi: (coordinates) => {
    return coordinates.map((coordinate) => {
      return coordinate.map(CoordinatesReverser._reverseCoordinates);
    });
  },

  _reverseMultiPolygonCoordinatesToMatchLeafletApi: (coordinates) => {
    return coordinates.map((coordinate) => {
      return coordinate.map((otherLevel) => {
        // Sometimes 'MultiPolygon' isnt a real MultiPolygon, but a normal Polygon
        if (typeof otherLevel[0] === 'number') {
          return CoordinatesReverser._reverseCoordinates(otherLevel);
        }
        return otherLevel.map(CoordinatesReverser._reverseCoordinates);
      });
    });
  },

  _reverseCoordinates: (lnglat) => {
    const latlng = lnglat.reverse();
    return latlng;
  }
};

export default CoordinatesReverser;
