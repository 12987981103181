/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
export const PROD_HOST = 'energieatlas.eon.de';

export const API_ENDPOINT = () => {
  if (typeof location === 'undefined') {
    return '';
  }

  if (location.host.includes(PROD_HOST)) {
    return 'https://eon-ea-backend-prod.azurewebsites.net/api';
  }
  if (location.host.includes('prod.eon-ea.diva-e.com')) {
    return 'https://prod.eon-ea.diva-e.com/api';
  }
  if (location.host.includes('dev.eon-ea.diva-e.com')) {
    // return 'https://dev.eon-ea.diva-e.com/api';
    return 'https://eon-ea-backend-dev.azurewebsites.net/api';
  }
  // Should be  https://dev.eon-ea.diva-e.com/api
  // Currently CERT is not trusted
  return 'https://eon-ea-backend-dev.azurewebsites.net/api';
};

export default {
  PUBSUB: {
    TOPIC_HOVER_MAP_ELEMENT: 'TOPIC_HOVER_MAP_ELEMENT'
  }
};
