import Cookies from 'js-cookie';

const cookieBannerHelper = {
  initJsCookiesWithoutEncoding: () => {
    return Cookies.withConverter({
      read(value) {
        return value;
      },
      write(value) {
        return value;
      }
    });
  },

  createDateOneYearFromNow: () => {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    return oneYearFromNow;
  },

  getCookieConsentCookieKey: () => {
    return 'consent_mgt';
  }
};

export default cookieBannerHelper;
