/* eslint-disable */
import ResponsiveHelperService from '../../services/ResponsiveHelperService';
import { PROD_HOST } from '../../constants';
import { isIframe } from '../../helper/iframeHelper';

class MapInitService {
  constructor() {
    if (typeof window !== 'undefined') {
      this.L = require('leaflet');
    }
    this.MAX_ZOOM = 17;
  }

  initMap() {
    let mymap;
    if (ResponsiveHelperService.isMinWidthDesktop()) {
      mymap = this.L.map('mapid', {
        zoomControl: false,
        maxZoom: this.MAX_ZOOM
      }).setView([50.5, 8.0], 6);
    } else if (isIframe()) {
      mymap = this.L.map('mapid', {
        zoomControl: false,
        dragging: true,
        tap: false,
        maxZoom: this.MAX_ZOOM
      }).setView([49.0, 10.0], 5);
    } else {
      mymap = this.L.map('mapid', {
        zoomControl: false,
        dragging: false,
        tap: false,
        maxZoom: this.MAX_ZOOM
      }).setView([49.0, 10.0], 5);
    }

    L.control
      .zoom({
        zoomInText: '',
        zoomOutText: '',
        position: 'topright'
      })
      .addTo(mymap);

    let apiKey;
    if (typeof location === 'undefined') {
      return '';
    }
    if (location.host.includes(PROD_HOST)) {
      apiKey = 'wSjO2WjfSsFBDTd7Fpq2-mJ3pPKTEHUOT6sZl97g5dY';
    } else {
      apiKey = '8J1FBBceQNVa0lUpcZ8oMPYAqgetpY2xt6x14KNi04g';
    }

    const hereTileUrl = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/reduced.day/{z}/{x}/{y}/512/png8?apiKey=${apiKey}&ppi=320&lg=ger`;
    const CartoDB_PositronNoLabels = this.L.tileLayer(hereTileUrl, {
      attribution:
        'Map Tiles &copy; ' +
        new Date().getFullYear() +
        ' ' +
        '<a href="http://developer.here.com">HERE</a>',
      subdomains: 'abcd',
      minZoom: 5,
      maxZoom: this.maxZoom,
      opacity: 0.5
    });
    CartoDB_PositronNoLabels.addTo(mymap);
    return mymap;
  }
}

export default MapInitService;
/* eslint-enable */
