/* eslint-env browser */

export const isIframe = () => {
  if (typeof window !== `undefined`) {
    const urlParams = new URLSearchParams(window.location.search);
    const accessMethod = urlParams.get('access_method');

    return accessMethod === 'iframe';
  }
};

export const checkIframeOptions = () => {
  if (typeof window !== `undefined`) {
    if (isIframe()) {
      const urlParams = new URLSearchParams(window.location.search);
      const ui = {
        stories: document.querySelector('.ea-layout__right-section'),
        footer: document.querySelector('.ea-footer-container'),
        header: document.querySelector('.ea-header__top-section')
      };

      // eslint-disable-next-line
      for (const [param, value] of urlParams) {
        if (value === 'false') {
          ui[param].style.display = 'none';
        }
      }
    }
  }
};

export const isLocalhost = () => {
  return window.location.href.includes('http://localhost:8000/');
};
