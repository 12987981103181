import React, { useEffect, useRef, useState, useCallback } from 'react';
import classnames from 'classnames';
import { node, string } from 'prop-types';
import './Box.scss';

const Box = ({ children, variant, className }) => {
  const boxRef = useRef(null);
  const boxContentRef = useRef(null);
  const [shadowTopVisible, setShadowTopVisible] = useState(false);
  const [shadowBottomVisible, setShadowBottomVisible] = useState(true);

  const setShadowVisiblity = useCallback(() => {
    if (boxRef.current.scrollTop < 60) {
      setShadowTopVisible(false);
    } else {
      setShadowTopVisible(true);
    }

    if (
      boxContentRef.current.getBoundingClientRect().height -
        boxRef.current.getBoundingClientRect().height >
      boxRef.current.scrollTop
    ) {
      setShadowBottomVisible(true);
    } else {
      setShadowBottomVisible(false);
    }
  }, []);

  useEffect(() => {
    const boxRefCurrent = boxRef.current;
    if (boxRef && boxRefCurrent) {
      boxRefCurrent.addEventListener('scroll', setShadowVisiblity, false);
    }
    return () => {
      boxRefCurrent.removeEventListener('scroll', setShadowVisiblity, false);
    };
  }, [setShadowVisiblity]);

  const classNames = classnames(
    'ea-box',
    className || null,
    shadowTopVisible && 'visible-top',
    shadowBottomVisible && 'visible-bottom',
    {
      'ea-box--large': variant === 'large',
      'ea-box--scroll-shadow': variant === 'large'
    }
  );

  return (
    <div className={classNames} ref={boxRef}>
      <div className="ea-box__content" ref={boxContentRef}>
        {children}
      </div>
    </div>
  );
};

Box.defaultProps = {
  variant: null,
  className: null
};

Box.propTypes = {
  children: node.isRequired,
  variant: string,
  className: string
};

export default Box;
