import { useCallback, useEffect } from 'react';

const useDebouncedEffect = (effect, delay, deps) => {
  const callback = useCallback(effect, [...deps, effect]);

  useEffect(() => {
    const timer = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [callback, delay]);
};

export default useDebouncedEffect;
