/* eslint-disable */
import React from 'react';
import GlobalContextProvider from './src/context/GlobalContextProvider';
import 'nodelist-foreach-polyfill';
import SEO from './src/shared-components/Seo'; // Adding IE 11 to browserlist and letting Babel automatically polyfill it wouldn't work


export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location;
  const scrollToTopRoutes = ['/notExistingRoute'];
  if (scrollToTopRoutes.indexOf(pathname) !== -1) {
    // eslint-disable-next-line
    window.scrollTo(0, 0);
  }

  return false;
};

/* eslint-disable no-underscore-dangle, no-undef */
export const onRouteUpdateDelayed = () => {
  window.___emitter.emit('onRouteUpdateDelayed');
};

export const onRouteUpdate = () => {
  window.___emitter.emit('onRouteUpdate');
};
/* eslint-enable no-underscore-dangle, no-undef */

export const wrapRootElement = ({ element }) => {
  return (
      <SEO>
        <GlobalContextProvider>{element}</GlobalContextProvider>
      </SEO>
  );
};
/* eslint-enable */
