/* eslint-disable prettier/prettier */
/* eslint-disable no-underscore-dangle */
import { API_ENDPOINT } from '../../constants';
import DataService from '../../services/DataService';
import CoordinatesReverser from './CoordinatesReverser';
import PATH_URLS from '../../constants/PATH_URLS';
import pathnameUtilities from '../../services/PathnameUtilities';
import mapApiResultTansformer from './MapApiResultTansformer';

const defaultOptions = {
  disableStorage: true
};
// const baseURLGeosCities = `${API_ENDPOINT()}/geo/cities`;
const baseURLTooltips = `${API_ENDPOINT()}`;
const baseURLRanks = `${API_ENDPOINT()}`;
const pathnameToBackendApiMappings = {
  geosRegion2: {
    [PATH_URLS.E_MOBILITY.LADESTATIONEN]: '/data/ladestationen.json'
  },
  tooltips: {
    // Grüne Energiemeister
    [PATH_URLS.GRUENE_ENERGIEMEISTER.MEISTER_INDEX]: `${baseURLTooltips}/masterIndex/tooltip`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.GESAMT]: `${baseURLTooltips}/facilities/tooltip`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.WIND]: `${baseURLTooltips}/facilities/tooltip`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.SOLAR]: `${baseURLTooltips}/facilities/tooltip`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.WASSERKRAFT]: `${baseURLTooltips}/facilities/tooltip`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.BIOMASSE]: `${baseURLTooltips}/facilities/tooltip`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.OEKOSTROMNUTZER]: `${baseURLTooltips}/energyUsage/tooltip`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.PATENTE]: `${baseURLTooltips}/patents/tooltip`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.ENERGIEMEISTER_LADESTATIONEN]: `${baseURLTooltips}/chargingStations/tooltip`,
    
    // E-Mobility
    [PATH_URLS.E_MOBILITY.E_AUTO_BESITZER]: `${baseURLRanks}/electricVehicles/tooltip`,
    [PATH_URLS.E_MOBILITY.LADESTATIONEN]: `${baseURLRanks}/chargers/tooltip`,
    [PATH_URLS.E_MOBILITY.E_BIKES]: `${baseURLRanks}/electricBicycles/tooltip`,
    
    // Haushalte
    [PATH_URLS.HAUSHALT.VORSAETZE]: `${baseURLTooltips}/newYear/resolution/tooltip`,
    [PATH_URLS.HAUSHALT.STREAMING]: `${baseURLTooltips}/streaming/tooltip`,
    
    // Zukunftsindex
    [PATH_URLS.ZUKUNFTSINDEX.CO2_EINSPARUNG_JAHR_GEPLANT]: `${baseURLTooltips}/futureIndex/tooltip`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_ENERGIEWENDE_ZUHAUSE.GESAMT]: `${baseURLTooltips}/futureIndex/tooltip`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_ENERGIEWENDE_ZUHAUSE.INSTALLATION_DACH_SOLARANLAGEN]: `${baseURLTooltips}/futureIndex/tooltip`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_ENERGIEWENDE_ZUHAUSE.INSTALLATION_BALKON_SOLARANLAGE]: `${baseURLTooltips}/futureIndex/tooltip`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_ENERGIEWENDE_ZUHAUSE.EFFIZIENTE_HEIZANALGE]: `${baseURLTooltips}/futureIndex/tooltip`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERKEHRSWENDE.GESAMT]: `${baseURLTooltips}/futureIndex/tooltip`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERKEHRSWENDE.UMSTIEG_E_AUTO]: `${baseURLTooltips}/futureIndex/tooltip`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERKEHRSWENDE.UMSTIEG_OEPNV]: `${baseURLTooltips}/futureIndex/tooltip`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERKEHRSWENDE.BIDIREKTIONALES_LADEM]: `${baseURLTooltips}/futureIndex/tooltip`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERBRAUCHSWENDE.GESAMT]: `${baseURLTooltips}/futureIndex/tooltip`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERBRAUCHSWENDE.HEIZTEMERATUR_SENKEN]: `${baseURLTooltips}/futureIndex/tooltip`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERBRAUCHSWENDE.SMARTE_THERMOSTATE]: `${baseURLTooltips}/futureIndex/tooltip`
  },
  ranksStates: {
    // Grüne Energiemeister
    [PATH_URLS.GRUENE_ENERGIEMEISTER.MEISTER_INDEX]: `${baseURLRanks}/masterIndex/rank/states`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.GESAMT]: `${baseURLRanks}/facilities/rank/states`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.WIND]: `${baseURLRanks}/facilities/rank/states/wind`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.SOLAR]: `${baseURLRanks}/facilities/rank/states/solar`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.WASSERKRAFT]: `${baseURLRanks}/facilities/rank/states/water`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.BIOMASSE]: `${baseURLRanks}/facilities/rank/states/biomass`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.OEKOSTROMNUTZER]: `${baseURLRanks}/energyUsage/rank/states`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.PATENTE]: `${baseURLRanks}/patents/rank/states`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.ENERGIEMEISTER_LADESTATIONEN]: `${baseURLRanks}/chargingStations/rank/states`,
    
    // E-Mobility
    [PATH_URLS.E_MOBILITY.E_AUTO_BESITZER]: `${baseURLRanks}/electricVehicles/rank/states`,
    [PATH_URLS.E_MOBILITY.LADESTATIONEN]: `${baseURLRanks}/chargers/rank/states`,
    [PATH_URLS.E_MOBILITY.E_BIKES]: `${baseURLRanks}/electricBicycles/rank/states`,
    
    // Haushalte
    [PATH_URLS.HAUSHALT.VORSAETZE]: `${baseURLTooltips}/newYear/resolution/rank/states`,
    [PATH_URLS.HAUSHALT.STREAMING]: `${baseURLTooltips}/streaming/rank/states`,
    
    // Zukunftsindex
    [PATH_URLS.ZUKUNFTSINDEX.CO2_EINSPARUNG_JAHR_GEPLANT]: `${baseURLRanks}/futureIndex/rank/states/co2Savings`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_ENERGIEWENDE_ZUHAUSE.GESAMT]: `${baseURLRanks}/futureIndex/rank/states/energyChange`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_ENERGIEWENDE_ZUHAUSE.INSTALLATION_DACH_SOLARANLAGEN]: `${baseURLRanks}/futureIndex/rank/states/energyChange/solarRoof`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_ENERGIEWENDE_ZUHAUSE.INSTALLATION_BALKON_SOLARANLAGE]: `${baseURLRanks}/futureIndex/rank/states/energyChange/solarBalcony`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_ENERGIEWENDE_ZUHAUSE.EFFIZIENTE_HEIZANALGE]: `${baseURLRanks}/futureIndex/rank/states/energyChange/efficientHeating`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERKEHRSWENDE.GESAMT]: `${baseURLRanks}/futureIndex/rank/states/transportChange`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERKEHRSWENDE.UMSTIEG_E_AUTO]: `${baseURLRanks}/futureIndex/rank/states/transportChange/electronicVehicle`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERKEHRSWENDE.UMSTIEG_OEPNV]: `${baseURLRanks}/futureIndex/rank/states/transportChange/publicTransport`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERKEHRSWENDE.BIDIREKTIONALES_LADEM]: `${baseURLRanks}/futureIndex/rank/states/transportChange/bidirectionalCharging`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERBRAUCHSWENDE.GESAMT]: `${baseURLRanks}/futureIndex/rank/states/consumptionChange`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERBRAUCHSWENDE.HEIZTEMERATUR_SENKEN]: `${baseURLRanks}/futureIndex/rank/states/consumptionChange/lowerHeating`,
    [PATH_URLS.ZUKUNFTSINDEX.DURCH_VERBRAUCHSWENDE.SMARTE_THERMOSTATE]: `${baseURLRanks}/futureIndex/rank/states/consumptionChange/smartThermostat`
  },
  ranksLandkreise: {
    // Grüne Energiemeister
    [PATH_URLS.GRUENE_ENERGIEMEISTER.MEISTER_INDEX]: `${baseURLRanks}/masterIndex/rank/districts`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.GESAMT]: `${baseURLRanks}/facilities/rank/districts`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.WIND]: `${baseURLRanks}/facilities/rank/districts/wind`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.SOLAR]: `${baseURLRanks}/facilities/rank/districts/solar`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.WASSERKRAFT]: `${baseURLRanks}/facilities/rank/districts/water`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN.BIOMASSE]: `${baseURLRanks}/facilities/rank/districts/biomass`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.OEKOSTROMNUTZER]: `${baseURLRanks}/energyUsage/rank/districts`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.PATENTE]: `${baseURLRanks}/patents/rank/districts`,
    [PATH_URLS.GRUENE_ENERGIEMEISTER.ENERGIEMEISTER_LADESTATIONEN]: `${baseURLRanks}/chargingStations/rank/districts`,
    
    // E-Mobility
    [PATH_URLS.E_MOBILITY.E_AUTO_BESITZER]: `${baseURLRanks}/electricVehicles/rank/districts`,
    [PATH_URLS.E_MOBILITY.LADESTATIONEN]: `${baseURLRanks}/chargers/rank/districts`,
    [PATH_URLS.E_MOBILITY.E_BIKES]: `${baseURLRanks}/electricBicycles/rank/districts`,
    
    // Haushalte
    [PATH_URLS.HAUSHALT.STREAMING]: `${baseURLRanks}/streaming/rank/districts`,
  }
};

class MapApi {
  constructor(pathname, options = defaultOptions) {
    if (!pathname) {
      throw new Error(`Api expected an pathname parameter but received: ${pathname}`);
    }

    this.pathname = pathname;
    this.options = options;

    this.pathnameAsKey = pathnameUtilities.createPathnameWithoutTrainlingSlash(
      pathname
    );
  }

  async fetchStates() {
    const requestUrl = `${API_ENDPOINT()}/geo/states`;
    const result = await new DataService().getData(requestUrl, {
      disableStorage: this.options.disableStorage,
      key: this.localStorageKey
    });

    CoordinatesReverser.reverseCoordinatesInGeoJson(result.data[0]);

    return result.data[0];
  }

  async fetchLandkreise() {
    const requestUrl = `${API_ENDPOINT()}/geo/districts`;
    const result = await new DataService().getData(requestUrl, {
      disableStorage: this.options.disableStorage,
      key: this.localStorageKey
    });

    CoordinatesReverser.reverseCoordinatesInGeoJson(result.data[0]);

    return result.data[0];
  }

  async fetchRegion2Geos() {
    const requestUrl = this._getRestApiPartForPathname(pathnameToBackendApiMappings.geosRegion2);
    if (!requestUrl) {
      return null;
    }

    const result = await new DataService().getData(requestUrl, {
      disableStorage: this.options.disableStorage,
      key: this.localStorageKey
    });

    return mapApiResultTansformer.transformResult(this.pathnameAsKey, result);
  }

  async fetchRanksForStates(year) {
    let requestUrl = this._getRestApiPartForPathname(pathnameToBackendApiMappings.ranksStates);
    if (!requestUrl) {
      return null;
    }

    if (year) {
      requestUrl = `${requestUrl}/${year}`;
    }

    let result;

    try {
      result = await new DataService().getData(requestUrl, {
        disableStorage: false,
        key: this.localStorageKey
      });
    } catch (e) {
      return null;
    }

    return result;
  }

  async fetchRanksForLandkreise(year) {
    let requestUrl = this._getRestApiPartForPathname(pathnameToBackendApiMappings.ranksLandkreise);
    if (!requestUrl) {
      return null;
    }

    if (year) {
      requestUrl = `${requestUrl}/${year}`;
    }

    let result;
    try {
      result = await new DataService().getData(requestUrl, {
        disableStorage: false,
        key: this.localStorageKey
      });
    } catch (e) {
      return null;
    }

    return result;
  }

  async fetchRanksForRegion2() {
    return [
      {
        ags: '03257000',
        rang: 1
      },
      {
        ags: '03453000',
        rang: 1
      },
      {
        ags: '06634000',
        rang: 3
      },
      {
        ags: '09761000',
        rang: 4
      }
    ];
  }

  async fetchTooltip(region, ags, year, category) {
    let requestUrl = this._getRestApiPartForPathname(pathnameToBackendApiMappings.tooltips);
    if (!requestUrl) {
      return null;
    }
    requestUrl = `${requestUrl}/${region}/${ags}`;
   
    if (category) {
      requestUrl += `/${category}`;
    }

    if (year) {
      requestUrl += `/${year}`;
    }

    const result = await new DataService().getData(requestUrl, {
      disableStorage: this.options.disableStorage,
      key: this.localStorageKey
    });

    return result;
  }

  _getRestApiPartForPathname(mapForCategory) {
    const apiEndpointPart = mapForCategory[this.pathnameAsKey];

    return apiEndpointPart;
  }
}

export default MapApi;
