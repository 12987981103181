// If we have entries that have the same value, we adjust the ranking so that both entries end up in the same place.

function adjustRanking(data, valueIndex, rankIndex) {
  const resultArray = [];
  data.forEach((item) => {
    if (!resultArray.length) return resultArray.push(item);
    const lastArrayItem = resultArray[resultArray.length - 1];

    if (item.rowData[valueIndex] === lastArrayItem.rowData[valueIndex]) {
      item.rowData[rankIndex] = lastArrayItem.rowData[rankIndex];
    }

    resultArray.push(item);
  });

  return resultArray;
}

export default adjustRanking;
