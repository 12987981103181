import PATH_URLS from '../../constants/PATH_URLS';

const MapApiResultTansformer = {
  transformResult: (pathname, result) => {
    switch (pathname) {
      case PATH_URLS.E_MOBILITY.LADESTATIONEN:
        return _transformLadestationen(result);
      default:
        return result;
    }
  }
};

function _transformLadestationen(result) {
  const transformedResult = result.map((entry) => {
    return {
      geometry: {
        type: 'Point',
        coordinates: [entry.long, entry.lat]
      }
    };
  });

  return { data: [{ features: transformedResult }] };
}

export default MapApiResultTansformer;
