const cookieConsentValueHelper = {
  getValueForFullyAcceptCookies: () => {
    return `15 - ${cookieConsentValueHelper._getOneYearFromNowAsFormattedString()}`;
  },

  getValueForFullyDeclineCookies: () => {
    return `1 - ${cookieConsentValueHelper._getOneYearFromNowAsFormattedString()}`;
  },

  areCookiesFullyAccepted: (consentCookie) =>
    consentCookie && consentCookie.split('-')[0].trim(' ') === '15',

  _getOneYearFromNowAsFormattedString: () => {
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
      oneYearFromNow
    );
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(
      oneYearFromNow
    );
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
      oneYearFromNow
    );

    return `${da}/${mo}/${ye}`;
  }
};

export default cookieConsentValueHelper;
