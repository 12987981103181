import React from 'react';
import PropTypes from 'prop-types';
import cookieBannerHelper from '../features/cookieBanner/cookieBannerHelper';
import cookieConsentValueHelper from '../features/cookieBanner/cookieConsentValueHelper';

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
  pendingRequests: 0,
  region: null,
  year: null,
  fallbackYear: null,
  availableYears: [],
  legend: null,
  decidedOnCookies: false,
  fullyAcceptedCookies: false
};

const _getInitialState = () => {
  const COOKIE_CONSENT_COOKIE_KEY =
    cookieBannerHelper.getCookieConsentCookieKey();
  const jscookies = cookieBannerHelper.initJsCookiesWithoutEncoding();
  const consentCookie = jscookies.get(COOKIE_CONSENT_COOKIE_KEY);

  return {
    ...initialState,
    decidedOnCookies: !!consentCookie,
    fullyAcceptedCookies:
      cookieConsentValueHelper.areCookiesFullyAccepted(consentCookie)
  };
};

export const BATCH_UPDATE_INITIAL_VALUES = 'BATCH_UPDATE_INITIAL_VALUES';

export const START_FETCHING = 'START_FETCHING';
export const FINISH_FETCHING = 'FINISH_FETCHING';

export const SET_REGION_FILTER = 'SET_REGION_FILTER';
export const RESET_REGION_FILTER = 'RESET_REGION_FILTER';

export const SET_TIMELINE_FILTER = 'SET_TIMELINE_FILTER';
export const RESET_TIMELINE_FILTER = 'RESET_TIMELINE_FILTER';

export const SET_AVAILABLE_YEARS = 'SET_AVAILABLE_YEARS';
export const RESET_AVAILABLE_YEARS = 'RESET_AVAILABLE_YEARS';

export const SET_LEGEND = 'SET_LEGEND';
export const RESET_LEGEND = 'RESET_LEGEND';

export const SET_CONSENT = 'SET_CONSENT';

function reducer(state, { type, payload }) {
  switch (type) {
    case START_FETCHING: {
      return {
        ...state,
        pendingRequests: state.pendingRequests + 1
      };
    }
    case FINISH_FETCHING: {
      return {
        ...state,
        pendingRequests: Math.max(0, state.pendingRequests - 1)
      };
    }
    case SET_REGION_FILTER: {
      return {
        ...state,
        region: payload
      };
    }
    case SET_TIMELINE_FILTER: {
      return {
        ...state,
        year: payload
      };
    }
    case SET_AVAILABLE_YEARS: {
      return {
        ...state,
        availableYears: payload
      };
    }
    case SET_LEGEND: {
      return {
        ...state,
        legend: payload
      };
    }
    case RESET_REGION_FILTER: {
      return {
        ...state,
        region: null
      };
    }
    case RESET_TIMELINE_FILTER: {
      return {
        ...state,
        year: null
      };
    }
    case RESET_AVAILABLE_YEARS: {
      return {
        ...state,
        availableYears: []
      };
    }
    case RESET_LEGEND: {
      return {
        ...state,
        legend: null
      };
    }
    case BATCH_UPDATE_INITIAL_VALUES: {
      return {
        ...state,
        ...payload
      };
    }
    case SET_CONSENT: {
      return {
        ...state,
        fullyAcceptedCookies: payload
      };
    }
    default:
      // eslint-disable-next-line
      console.error(`Unknown action type ${type}`);
      return state;
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, _getInitialState());
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default GlobalContextProvider;
