const mapHelper = {
  enrichGeosWithRangs: (ranksForLandkreise, landkreiseToRender) => {
    if (!ranksForLandkreise) {
      return landkreiseToRender;
    }

    const landKreiseDeepCopy = JSON.parse(JSON.stringify(landkreiseToRender));

    landKreiseDeepCopy.features = landKreiseDeepCopy.features.map(
      (landkreisGeo) => {
        const foundRank = ranksForLandkreise.find((rank) => {
          return rank.ags === landkreisGeo.properties.ags;
        });

        if (foundRank) {
          landkreisGeo.properties.rank = foundRank.rank;
        }

        return landkreisGeo;
      }
    );

    return landKreiseDeepCopy;
  }
};

export default mapHelper;
