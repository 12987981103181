/* eslint-disable no-underscore-dangle */
import pathnameUtilities from '../../services/PathnameUtilities';

const defaultOptions = {
  disableStorage: true,
  year: null,
  region: null
};

class TimelineApi {
  constructor(pathname, options = defaultOptions) {
    if (!pathname) {
      throw new Error(
        `Api expected an pathname parameter but received: ${pathname}`
      );
    }

    this.pathnameAsKey =
      pathnameUtilities.createPathnameWithoutTrainlingSlash(pathname);
    this.options = options;
  }

  _getRestApiPartForPathname(mapForCategory) {
    const apiEndpointPart = mapForCategory[this.pathnameAsKey];

    return apiEndpointPart;
  }

  setPathname(pathname) {
    this.pathnameAsKey =
      pathnameUtilities.createPathnameWithoutTrainlingSlash(pathname);
  }
}

export default TimelineApi;
