import PATH_URLS from '../constants/PATH_URLS';

const pathnameUtilities = {
  createPathnameWithoutTrainlingSlash: (pathname) => {
    if (pathname.slice(-1) === '/' && pathname.length > 1) {
      const pathnameWithoutTrailingSlash = pathname.substring(
        0,
        pathname.length - 1
      );
      return pathnameWithoutTrailingSlash;
    }

    return pathname;
  },

  isAnyEEAnlagenpage: (pathname) => {
    let isAnyEEAnlagenpage = false;
    Object.keys(PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN).forEach((key) => {
      if (pathname === PATH_URLS.GRUENE_ENERGIEMEISTER.EE_ANLAGEN[key]) {
        isAnyEEAnlagenpage = true;
      }
    });

    return isAnyEEAnlagenpage;
  },

  isEAutoBesitzerPage: (pathname) => {
    let isEAutoBesitzerPage = false;
    if (pathname === PATH_URLS.E_MOBILITY.E_AUTO_BESITZER) {
      isEAutoBesitzerPage = true;
    }

    return isEAutoBesitzerPage;
  },

  isLadestationenPage: (pathname) => {
    if (pathname === PATH_URLS.E_MOBILITY.LADESTATIONEN) {
      return true;
    }

    return false;
  }
};

export default pathnameUtilities;
