import React from 'react';
import './Topline.scss';

const Topline = () => (
  <div className="ea-topline">
    <div className="ea-topline__left" />
    <div className="ea-topline__right" />
    <div className="ea-topline__narrow-right" />
  </div>
);

export default Topline;
