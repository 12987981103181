import React from 'react';
import PropTypes from 'prop-types';
import DimensionConstrain from '../../../shared-components/Layout/DimensionConstrain/DimensionConstrain';
import makeTabnavigationLinks from './helper/makeTabnavigationLinks';

import './Tabnavigation.scss';

const Tabnavigation = ({ tabnaviConfig, pageRenderPath, pathname }) => {
  const tabNavigationLinks = makeTabnavigationLinks(
    pageRenderPath,
    tabnaviConfig
  );
  return (
    <div className="ea-tabnavigation-scrollwrapper">
      <DimensionConstrain key={pathname}>
        <nav>
          <ul className="ea-tabnavigation">{tabNavigationLinks}</ul>
        </nav>
      </DimensionConstrain>
    </div>
  );
};

Tabnavigation.propTypes = {
  tabnaviConfig: PropTypes.arrayOf(
    PropTypes.exact({
      displayName: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  pageRenderPath: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired
};

export default Tabnavigation;
