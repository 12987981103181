import React, {
  useEffect,
  useRef,
  useContext,
  useState,
  useCallback
} from 'react';
import { any, number, string } from 'prop-types';
import _ from 'lodash';
import {
  FINISH_FETCHING,
  SET_LEGEND,
  RESET_LEGEND,
  GlobalDispatchContext,
  GlobalStateContext,
  START_FETCHING,
  SET_TIMELINE_FILTER
} from '../../../context/GlobalContextProvider';
import './Map.scss';
import MapInitService from '../MapInitService';
import MapDrawService, { REGIONS } from '../MapDrawService';
import withLocation from '../../../HOCs/withLocation/withLocation';
import MapApi from '../MapApi';
import pathnameUtilities from '../../../services/PathnameUtilities';
import { checkIframeOptions } from '../../../helper/iframeHelper';
import mapHelper from '../MapHelper';

const Map = ({ initialYear, location, category }) => {
  const [isZooming, setIsZooming] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const mymap = useRef(null);
  const mapDrawServiceRef = useRef(null);
  const mapApiRef = useRef(null);
  const globalState = useContext(GlobalStateContext);
  const globalDispatch = useContext(GlobalDispatchContext);
  const [currentPathname, setCurrentPathname] = useState(
    pathnameUtilities.createPathnameWithoutTrainlingSlash(location.pathname)
  );
  const [selectedRegion, setSelectedRegion] = useState('notset');
  const [selectedYear, setSelectedYear] = useState(globalState.year);
  const [statesGeos, setStatesGeos] = useState({});
  const [landkreiseGeos, setLandkreiseGeos] = useState({});
  const region2GeosRef = useRef({});

  const handleMapMove = ({ addHiddenClass = false }) => {
    const body = document.querySelector('body');
    if (addHiddenClass && !body.classList.contains('overflow-hidden')) {
      setIsMoving(addHiddenClass);
    }
    setIsMoving(addHiddenClass);
  };

  const handleZoom = ({ addHiddenClass = false }) => {
    const body = document.querySelector('body');
    if (addHiddenClass && !body.classList.contains('overflow-hidden')) {
      setIsZooming(addHiddenClass);
    }
    setIsZooming(addHiddenClass);
  };

  useEffect(() => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    const elements = [html, body];

    elements.forEach((element) => {
      if (isZooming || isMoving) {
        element.classList.add('overflow-hidden');
      } else {
        element.classList.remove('overflow-hidden');
      }
    });
  }, [isZooming, isMoving]);

  const getRegion2GeosAndRender = useCallback(async () => {
    let region2Geos;
    const locationAsKey = pathnameUtilities.createPathnameWithoutTrainlingSlash(
      location.pathname
    );
    if (region2GeosRef.current[locationAsKey]) {
      region2Geos = region2GeosRef.current[locationAsKey];
    } else {
      const response = await mapApiRef.current.fetchRegion2Geos();
      if (response) {
        region2Geos = response.data[0].features;

        region2GeosRef.current = {
          ...region2GeosRef.current,
          [locationAsKey]: region2Geos
        };
      }
    }

    const mapDrawService = mapDrawServiceRef.current;
    if (region2Geos) {
      mapDrawService.renderGeoJsonsMarkers(locationAsKey, {
        features: region2Geos
      });
    }

    // TODO: Also enrich geos with ranks before rendering? Like with polygons
    // When to enrich? If it flickers on user actions
    mapDrawService.updateRankOfMarkers(
      await mapApiRef.current.fetchRanksForRegion2()
    );
  }, [mapApiRef, location.pathname, region2GeosRef]);

  useEffect(() => {
    mapApiRef.current = new MapApi(location.pathname);
    if (mapDrawServiceRef.current) {
      mapDrawServiceRef.current.setMapApi(mapApiRef.current);
    }

    if (mapDrawServiceRef.current) {
      mapDrawServiceRef.current.setCurrentPathname(
        pathnameUtilities.createPathnameWithoutTrainlingSlash(location.pathname)
      );
    }
  });

  useEffect(() => {
    checkIframeOptions();
  }, []);

  useEffect(() => {
    mymap.current = new MapInitService().initMap();
    mymap.current.on('movestart', () => {
      handleMapMove({ addHiddenClass: true });
    });
    mymap.current.on('moveend', () => {
      handleMapMove({ addHiddenClass: false });
    });
    mymap.current.on('zoomstart', () => handleZoom({ addHiddenClass: true }));
    mymap.current.on(
      'zoomend',
      _.debounce(() => handleZoom({ addHiddenClass: false }), 400)
    );
    mapDrawServiceRef.current = new MapDrawService(
      mapApiRef.current,
      mymap.current,
      null
    );

    return () => {
      mymap.current.off('zoomstart', handleZoom);
      mymap.current.off('zoomend', handleZoom);
      mymap.current.off('movestart', handleMapMove);
      mymap.current.off('moveend', handleMapMove);
    };
  }, []);

  /**
   * Pathname changes useEffect
   */
  useEffect(() => {
    const onPathnameChange = async () => {
      if (currentPathname === location.pathname) {
        return;
      }
      setCurrentPathname(location.pathname);
      globalDispatch({ type: START_FETCHING });

      const mapDrawService = mapDrawServiceRef.current;

      let yearToUse;
      const isSwitchFromOekostromToOekostrom =
        currentPathname.split('/')[1] === 'oekostrom' &&
        location.pathname.split('/')[1] === 'oekostrom';
      if (isSwitchFromOekostromToOekostrom) {
        yearToUse = globalState.year;
      } else {
        yearToUse = initialYear;

        globalDispatch({
          type: SET_TIMELINE_FILTER,
          payload: initialYear
        });
        mapDrawService.setSelectedYear(initialYear);
        mapDrawService.setCategory(category);
      }

      let ranks;
      if (!globalState.region || globalState.region === 0) {
        mapDrawService.resetAllTooltipsForPolygon(REGIONS.STATES);

        ranks = await mapApiRef.current.fetchRanksForStates(yearToUse);

        mapDrawService.updateRankOfPolygons(ranks ? ranks.data : null);
      } else if (globalState.region === 1) {
        mapDrawService.resetAllTooltipsForPolygon(REGIONS.DISTRICS);

        ranks = await mapApiRef.current.fetchRanksForLandkreise(yearToUse);
        mapDrawService.updateRankOfPolygons(ranks ? ranks.data : null);
      } else if (globalState.region === 2) {
        mapDrawService.clearMap();
        await getRegion2GeosAndRender();
      }

      _writeLegendToContext(ranks);
      globalDispatch({ type: FINISH_FETCHING });

      checkIframeOptions();
    };

    onPathnameChange();
  });

  /**
   * Region changes useEffect
   */
  useEffect(() => {
    function renderGeoJsons() {
      globalDispatch({ type: START_FETCHING });

      // Give React a chance to start the loader
      setTimeout(async () => {
        const mapDrawService = mapDrawServiceRef.current;
        mapDrawService.clearMap();

        const mapApi = mapApiRef.current;

        let ranks;
        if (!globalState.region || globalState.region === 0) {
          if (!statesGeos.features) {
            const states = await mapApi.fetchStates();
            setStatesGeos(states);
            mapDrawService.renderGeoJsonsPolygons(REGIONS.STATES, states);
          } else {
            mapDrawService.renderGeoJsonsPolygons(REGIONS.STATES, statesGeos);
          }

          // globalState.year isnt filled when reloading the page in this useEffect, i dont know why
          // -> Use initialYear from PAGES_CONFIGS
          const yearToLoad = globalState.year ? globalState.year : initialYear;
          ranks = await mapApiRef.current.fetchRanksForStates(yearToLoad);
          mapDrawService.updateRankOfPolygons(ranks ? ranks.data : null);
        } else if (globalState.region === 1) {
          let landkreiseToRender;
          if (!landkreiseGeos.features) {
            const landkreise = await mapApi.fetchLandkreise();
            setLandkreiseGeos(landkreise);
            landkreiseToRender = landkreise;
          } else {
            landkreiseToRender = landkreiseGeos;
          }

          ranks = await mapApiRef.current.fetchRanksForLandkreise(
            globalState.year
          );

          const enrichedGeosWithRanks = mapHelper.enrichGeosWithRangs(
            ranks ? ranks.data : null,
            landkreiseToRender
          );
          mapDrawService.renderGeoJsonsPolygons(
            REGIONS.DISTRICS,
            enrichedGeosWithRanks
          );
        } else if (globalState.region === 2) {
          await getRegion2GeosAndRender();
        }

        _writeLegendToContext(ranks);
        globalDispatch({ type: FINISH_FETCHING });
      }, 0);
    }

    const onSelectedRegionChange = () => {
      // Prevent rendering twice when refreshing on Energiemeister for example
      // globalState.region is first null, then 0... which is both different then selectedRegion for two times
      // We catch the "second different" here
      if (selectedRegion === null && globalState.region === 0) {
        setSelectedRegion(globalState.region);
        return;
      }
      if (selectedRegion !== globalState.region) {
        setSelectedRegion(globalState.region);

        renderGeoJsons();
      }
    };

    onSelectedRegionChange();
  });

  /**
   * Year/Zeitschiene changes useEffect
   */
  useEffect(() => {
    setTimeout(async () => {
      if (selectedYear === globalState.year || !globalState.year) {
        return;
      }
      setSelectedYear(globalState.year);

      globalDispatch({ type: START_FETCHING });

      const mapDrawService = mapDrawServiceRef.current;
      mapDrawService.setSelectedYear(globalState.year);
      mapDrawService.setCategory(category);

      let ranks;
      if (!globalState.region || globalState.region === 0) {
        mapDrawService.resetAllTooltipsForPolygon(REGIONS.STATES);
        if (globalState.year) {
          ranks = await mapApiRef.current.fetchRanksForStates(globalState.year);
          mapDrawService.updateRankOfPolygons(ranks ? ranks.data : null);
        }
      } else if (globalState.region === 1) {
        mapDrawService.resetAllTooltipsForPolygon(REGIONS.DISTRICS);
        ranks = await mapApiRef.current.fetchRanksForLandkreise(
          globalState.year
        );
        mapDrawService.updateRankOfPolygons(ranks ? ranks.data : null);
      } else if (globalState.region === 2) {
        mapDrawService.clearMap();
        getRegion2GeosAndRender();
        mapDrawService.updateRankOfMarkers(
          await mapApiRef.current.fetchRanksForRegion2()
        );
      }

      _writeLegendToContext(ranks);
      globalDispatch({ type: FINISH_FETCHING });
    }, 0);

    // onSelectedYearChange();
  });

  function _writeLegendToContext(ranks) {
    if (!ranks) {
      globalDispatch({ type: RESET_LEGEND });
      return;
    }
    globalDispatch({
      type: SET_LEGEND,
      payload: {
        values2DimArray: ranks.metaData.legend,
        unit: ranks.metaData.unit
      }
    });
  }

  return <div id="mapid" />;
};

Map.defaultProps = {
  initialYear: null,
  category: null
};

Map.propTypes = {
  location: any.isRequired,
  initialYear: number,
  category: string
};

export default withLocation(Map);
