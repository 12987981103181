import formatNumberValue from '../../../../helper/formatNumberValue';
import adjustRanking from '../adjustRanking';
import { GERMANY_NAME } from '../constants';
import getTableHead from '../getTableHead';
import sortData from '../sortData';

export const UNIT_MW = 'MW';
export const UNIT_KW = 'kW';

function parser(dataItem, index, region) {
  const { name, ags } = dataItem;
  const rank = name === GERMANY_NAME ? '-' : index + 1;
  // const stations = formatNumberValue(dataItem.values.stations, 0, 0);
  const points = formatNumberValue(dataItem.values.points, 0, 0);
  let totalPowerRating = dataItem.values.totalPowerRatingMW;
  if (totalPowerRating) {
    if (region === 1) {
      totalPowerRating = `${formatNumberValue(
        totalPowerRating * 1000,
        0,
        0
      )}&nbsp;${UNIT_KW}`;
    } else {
      totalPowerRating = `${formatNumberValue(
        totalPowerRating
      )}&nbsp;${UNIT_MW}`;
    }
  }

  const totalPowerRatingKWPer1000 =
    dataItem.values.totalPowerRatingKWPer1000 &&
    `${formatNumberValue(
      dataItem.values.totalPowerRatingKWPer1000
    )}&nbsp;${UNIT_KW}`;

  return {
    name,
    ags,
    rowData: [
      name,
      rank,
      points,
      // stations,
      totalPowerRating,
      totalPowerRatingKWPer1000
    ]
  };
}

function chargersDataParser(apiResponse, { region, pageData }) {
  const states = apiResponse.data.filter(
    (state) => state.name !== GERMANY_NAME
  );
  const germany = apiResponse.data.filter(
    (state) => state.name === GERMANY_NAME
  );

  sortData(states, 'points');

  if (apiResponse.data[0].year === 2020) {
    sortData(states, 'stations');
  }

  const stateValues = states.map((state, index) =>
    parser(state, index, region)
  );
  const germanyValues = germany.map((state, index) =>
    parser(state, index, region)
  );

  let result = null;

  if (apiResponse.data[0].year === 2020) {
    result = adjustRanking(stateValues, 3, 1);
  } else {
    result = adjustRanking(stateValues, 2, 1);
  }

  const tableHead = getTableHead(region, pageData.meta.tableHead.region);

  return {
    thead: tableHead,
    tbody: [...germanyValues, ...result]
  };
}

export default chargersDataParser;
